import React, {useEffect} from "react";
import useSociety from "../../../hooks/useSociety";
import styles from "./SocietyAdmin.module.css";
import {useSectionsRefs} from "../../../components/mainWrapper/MainWrapper";
import SocietyAdminGeneral from "../../../components/societyAdmin/societyAdminGeneral/SocietyAdminGeneral";
import SocietyAdminContact from "../../../components/societyAdmin/societyAdminContact/SocietyAdminContact";
import SocietyAdminArticles from "../../../components/societyAdmin/societyAdminArticles/SocietyAdminArticles";
import SocietyHomepageDescription
    from "../../../components/societyHomepage/societyHomepageDescription/SocietyHomepageDescription";
import SocietyHomepageArticles
    from "../../../components/societyHomepage/societyHomepageArticles/SocietyHomepageArticles";
import SocietyHomepageContact from "../../../components/societyHomepage/societyHomepageContact/SocietyHomepageContact";

const SocietyHomepage = () => {

    const {setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    useEffect(() => {
        setNavItems([{title: 'ACCUEIL'}, {title: 'ARTICLES'}, {title: 'CONTACT'}])
        setDisplayRegisterSocietyButton(false);
    }, [setDisplayRegisterSocietyButton, setNavItems]);

    return (
        <div className={styles.container}>
            {/*<SocietyAdminGeneral/>*/}
            <SocietyHomepageDescription/>
            <SocietyHomepageArticles/>
            <SocietyHomepageContact/>
            {/*<SocietyHomepageContact/>*/}
            {/*<SocietyAdminContact/>*/}
            {/*<SocietyAdminArticles/>*/}
        </div>

    );
}

export default SocietyHomepage