import React, {useEffect} from "react";
import SocietiesList from "../../components/societiesList/SocietiesList";
import Contact from "../../components/contact/Contact";
import GlobalSearch from "../../components/globalSearch/GlobalSearch";
import About from "../../components/about/About";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import styles from "./Homepage.module.css";
import useDataFetch from "../../hooks/useDataFetch";
import {SocietyDTO} from "../../context/SocietyProvider";

const Homepage = () => {

    const {societiesSection, aboutSection, contactSection, setNavItems, setDisplayRegisterSocietyButton, setDisplayLoginButton, setDisplayMobileMenu} = useSectionsRefs();

    const {data: societies} = useDataFetch<SocietyDTO>(
        `/societies`, false
    );

    useEffect(() => {
        const navItems = [
            {title: 'Sociétés', scrollRef: societiesSection},
            {title: 'À Propos', scrollRef: aboutSection},
            {title: 'Contact', scrollRef: contactSection},
        ];
        setNavItems(navItems);
        setDisplayRegisterSocietyButton(true);
        setDisplayLoginButton(true);
        setDisplayMobileMenu(true);
    }, [societiesSection, aboutSection, contactSection, setNavItems, setDisplayRegisterSocietyButton, setDisplayLoginButton]);

    return (
        <div className={styles.container}>
            <GlobalSearch societies={societies ? societies : []}/>
            <SocietiesList societies={societies ? societies : []}/>
            <About/>
            <Contact/>
        </div>
    );
}

export default Homepage