import React, {useState} from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CustomTextField from "../../../customMUIComponents/CustomTextField";
import useDataPost from "../../../../hooks/useDataPost";
import {SocietyDTO} from "../../../../context/SocietyProvider";
import useSociety from "../../../../hooks/useSociety";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface AddArticleModalProps {
    open: boolean,
    setOpen: (open: boolean) => void
}

const AddArticleModal = ({open, setOpen}: AddArticleModalProps) => {
    const {society} = useSociety();

    const [title, setTitle] = useState<string>('');
    const [price, setPrice] = useState<string>('');
    const [image, setImage] = useState<File | null>(null);

    const {postData} = useDataPost<SocietyDTO>(`/societies/${society.id}/products`, {}, true, false, true);

    const handleClose = () => {
        setOpen(false);
    }

    const addProduct = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        const formData: any = new FormData();
        formData.append('name', title)
        formData.append('price', 100)
        image && formData.append('image', image)
        formData.append('visible', true)

        await postData(undefined, formData);
        window.location.reload();
    };

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} textAlign={'center'}>
                    <form onSubmit={(e) => addProduct(e)}>
                        <Typography
                            id="modal-modal-title"
                            variant="secondVariant"
                            component="h2"
                            sx={{marginBottom: 2}}
                        >
                            Ajouter un article
                        </Typography>

                        <CustomTextField
                            value={title}
                            label="Titre"
                            mb={4}
                            type="text"
                            required
                            setField={setTitle}
                            variant="outlined"
                        />

                        <CustomTextField
                            value={title}
                            label="Description"
                            mb={4}
                            type="text"
                            required
                            setField={setTitle}
                            variant="outlined"
                            multiline={true}
                            rows={4}
                        />

                        <CustomTextField
                            value={price}
                            label="Prix"
                            mb={4}
                            type="text"
                            required
                            setField={setPrice}
                            variant="outlined"
                        />

                        <input
                            type="file"
                            accept="image/*"
                            onChange={(event) => {
                                event.target.files && event.target.files.length > 0 && setImage(event.target.files[0])
                            }}
                        />

                        <Button
                            type="submit"
                            variant="firstVariant"
                            fullWidth
                        >
                            Ajouter l'article
                        </Button>
                    </form>
                </Box>
            </Modal>
        </>
    );
}

export default AddArticleModal