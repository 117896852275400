import React, {useEffect} from "react";
import {useSectionsRefs} from "../../components/mainWrapper/MainWrapper";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

const Unauthorized = () => {

    const {setNavItems, setDisplayRegisterSocietyButton} = useSectionsRefs();

    useEffect(() => {
        setNavItems([{title: ''}]) //TODO   ugly hack
        setDisplayRegisterSocietyButton(false);
    }, [setNavItems, setDisplayRegisterSocietyButton]);

    return (
        <Box
            sx={{
                width: '100%',
                backgroundColor: 'primary.main',
                alignContent: 'center'
            }}
        >
            <Typography
                sx={{
                    fontSize: {xs: 25, sm: 30},
                }}
            >
                Accès non autorisé. Veuillez vous connecter avec un compte autorisé pour accéder à cette page.
            </Typography>
        </Box>
    );
}

export default Unauthorized