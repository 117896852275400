import React, {useEffect, useState} from "react";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import {Alert} from "@mui/material";
import useLogin from "../../hooks/useLogin";
import useAuth from "../../hooks/useAuth";
import CustomTextField from "../customMUIComponents/CustomTextField";

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
};

interface LoginModalProps {
    open: boolean,
    setOpen: (open: boolean) => void
}

const LoginModal = ({open, setOpen}: LoginModalProps) => {
    const [email, setEmail] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [clicked, setClicked] = useState<boolean>(false);
    const handleClose = () => {
        setOpen(false);
        setClicked(false);
    }

    const {login, error} = useLogin();
    const {auth} = useAuth();

    const authenticate = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setClicked(true);
        login(email, password);
    }

    useEffect(() => {
        if (auth.token && clicked) {
            window.location.reload();
        }
    }, [auth, clicked]);

    return (
        <>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} textAlign={'center'}>
                    <form onSubmit={(e) => authenticate(e)}>
                        <Typography
                            id="modal-modal-title"
                            variant="secondVariant"
                            component="h2"
                            sx={{marginBottom: 2}}
                        >
                            Connexion
                        </Typography>
                        <CustomTextField
                            value={email}
                            label="Email"
                            mb={4}
                            type="email"
                            required
                            setField={setEmail}
                            variant="outlined"
                        />

                        <CustomTextField
                            value={password}
                            label="Mot de passe"
                            mb={4}
                            type="password"
                            required
                            setField={setPassword}
                            variant="outlined"
                        />

                        <Button
                            type="submit"
                            variant="firstVariant"
                            fullWidth
                        >
                            Se connecter
                        </Button>
                        {error && (
                            <Alert
                                severity="error"
                                sx={{mt: 4}}
                            >
                                L'adresse email ou le mot de passe est incorrect.
                            </Alert>
                        )}
                    </form>
                </Box>
            </Modal>
        </>
    );
}

export default LoginModal