import useAuth from "./useAuth";
import axios from "../api/axios";
import {AxiosResponse} from "axios";
import {useState} from "react";
import {useNavigate} from "react-router-dom";

export interface AuthenticationResponse {
    token: string;
    societyId: number;
}

const useLogin = () => {

    const [error, setError] = useState<any>(null);

    const {setAuth} = useAuth();

    const navigate = useNavigate();

    const login = (email: string, password: string) => {
        axios.post<AuthenticationResponse>(`auth/authenticate`, {
            email,
            password
        }, {withCredentials: true}).then((response: AxiosResponse<AuthenticationResponse>) => {
            const authenticationResponse: AuthenticationResponse = response.data;
            setAuth({token: authenticationResponse.token, societyId: authenticationResponse.societyId});
            setError(null);
            navigate('/');
        }).catch((error) => {
            setError(error);
        })
    }

    return {login, error};

}

export default useLogin;