import React from "react";
import {MenuItem} from "@mui/material";
import CustomTextField from "../../customMUIComponents/CustomTextField";

interface BankDataProps {
    isSelling: boolean | undefined,
    setIsSelling: (isSelling: boolean) => void,
    iban: string,
    setIban: (iban: string) => void,
    bankName: string,
    setBankName: (bankName: string) => void,
}

const BankData = ({isSelling, setIsSelling, iban, setIban, bankName, setBankName}: BankDataProps) => {

    return (
        <>
            <CustomTextField
                variant={"outlined"}
                value={isSelling === undefined ? "" : isSelling ? "yes" : "no"}
                label={"Souhaitez-vous vendre des produits en ligne ?"}
                setField={(fieldValue) => setIsSelling(fieldValue === "yes")}
                select
                selectOptions={[
                    <MenuItem key="yes" value={'yes'}>Oui</MenuItem>,
                    <MenuItem key="no" value={'no'}>Non</MenuItem>
                ]}
                mb={4}
                textAlign={"left"}
                required
            />
            {isSelling && (
                <>
                    <CustomTextField
                        value={iban}
                        setField={setIban}
                        label="IBAN"
                        required
                        mb={4}
                        variant={"outlined"}
                    />
                    <CustomTextField
                        value={bankName}
                        setField={setBankName}
                        label="Nom de votre banque"
                        required
                        mb={4}
                        variant={"outlined"}
                    />
                </>
            )}
        </>
    );
}

export default BankData