import React, {useState} from "react";
import styles from "./SocietyHomepageArticles.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import ArticleCard from "../../articleCard/ArticleCard";
import {Button, Grid2} from "@mui/material";
import useSociety from "../../../hooks/useSociety";
import AddArticleModal from "../../societyAdmin/societyAdminArticles/addArticleModal/AddArticleModal";
import useDataFetch from "../../../hooks/useDataFetch";
import {ProductDTO} from "../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";

const SocietyHomepageArticles = () => {

    const {society} = useSociety();

    const [open, setOpen] = useState<boolean>(false);

    const {data: articles} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, true, true);

    const {articlesSection} = useSectionsRefs();

    return (
        <>
            <Box
                ref={articlesSection}
                sx={{
                    backgroundColor: "secondary.main",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <div className={styles.content}>
                    <Typography
                        sx={{
                            color: '#ffffff',
                            fontSize: {xs: 30, sm: 40},
                        }}
                        variant={"firstVariant"}
                    >
                        ARTICLES
                    </Typography>

                    {articles && articles.length === 0 && (
                        <Typography
                            sx={{
                                color: '#ffffff',
                                fontSize: {xs: 22, sm: 26},
                            }}
                            variant={"firstVariant"}
                        >
                            Aucun article n'est disponible actuellement. Revenez plus tard !
                        </Typography>
                    )}


                    {articles && (
                        <Grid2 container spacing={6} mt={2}>
                            {articles.map((article: ProductDTO) => (
                                <ArticleCard key={article.id} article={article}/>
                            ))}
                        </Grid2>
                    )}

                    {society.secured && (
                        <Button
                            sx={{
                                mt: 4,
                            }}
                            variant={"fourthVariant"}
                            onClick={() => setOpen(true)}
                        >
                            Ajouter un article
                        </Button>
                    )}
                </div>
            </Box>
            <AddArticleModal open={open} setOpen={setOpen}/>
        </>
    );
}

export default SocietyHomepageArticles

// import React from "react";
// import useSociety from "../../../hooks/useSociety";
// import useDataFetch from "../../../hooks/useDataFetch";
// import {ProductDTO} from "../../societyAdmin/societyAdminArticles/societyAdminArticlesList/SocietyAdminArticlesList";
// import useBasket from "../../../hooks/useBasket";
//
// const SocietyHomepageArticles = () => {
//
//     const {society} = useSociety();
//     const {basket, addToBasket, reduceFromBasket} = useBasket();
//
//     const {data: products} = useDataFetch<ProductDTO[]>(`/societies/${society.id}/products`, false, true);
//
//     return (
//         <div style={{border: '1px solid black'}}>
//             <h3>Articles</h3>
//             <ul>
//                 {products && products.filter((product: ProductDTO) => product.visible).map((product: ProductDTO, index: number) => (
//                     <li style={{border: '1px solid black'}}
//                         key={index}>
//                         Id: {product.id} <br></br>
//                         Nom: {product.name} <br></br>
//                         Prix: {product.price}CHF <br></br>
//                         Quantité: {product.id ? basket.get(product.id) ?? 0 : 0} <br></br>
//                         <img style={{width: '200px'}} src={`https://looty.ch:8443/api/v1/societies/${society.id}/products/${product.id}/image`} alt="product"/>
//                         <button onClick={() => addToBasket(product)}>Ajouter au panier</button>
//                         <button onClick={() => reduceFromBasket(product)}>Décrémenter du panier</button>
//                         <br></br>
//                     </li>
//                 ))}
//             </ul>
//         </div>
//     );
// }
//
// export default SocietyHomepageArticles