import React, {useEffect} from "react";
import {MenuItem} from "@mui/material";
import CustomTextField from "../../customMUIComponents/CustomTextField";
import Box from "@mui/material/Box";
import {useLocation} from "react-router-dom";
import {RateEnum} from "../../../enums/RateEnum";

export interface SubscriptionSelectionDataProps {
    subscriptionChoice: string,
    setSubscriptionChoice: (subscriptionChoice: string) => void,
}

const subscriptionsMenuItemOptions = [
    {
        rateEnum: RateEnum.STARTER,
        textLong: 'Starter: Aucun frais mensuel, 5% par transaction (min. 0.50CHF)',
        textShort: 'Starter: Aucun frais mensuel'
    },
    {
        rateEnum: RateEnum.PRO,
        textLong: 'Pro: Aucun frais mensuel, 5% par transaction (min. 0.50CHF)',
        textShort: 'Pro: Aucun frais mensuel'
    },
    {
        rateEnum: RateEnum.BOOKING,
        textLong: 'Réservation: Aucun frais mensuel, 5% par transaction (min. 0.50CHF)',
        textShort: 'Réservation: Aucun frais mensuel'
    },
    {
        rateEnum: RateEnum.SALES,
        textLong: 'Vente: Aucun frais mensuel, 5% par transaction (min. 0.50CHF)',
        textShort: 'Vente: Aucun frais mensuel'
    },
]

const SubscriptionSelection = ({subscriptionChoice, setSubscriptionChoice}: SubscriptionSelectionDataProps) => {

    const {hash} = useLocation();

    const hashIsPresent: boolean = !!hash && hash.length > 0;

    const rateEnum: string = hashIsPresent ? hash.substring(1, hash.length).toUpperCase() : '';

    const rateEnumExists = Object.values(RateEnum).includes(rateEnum.toUpperCase() as RateEnum);

    useEffect(() => {
        if (rateEnumExists) {
            setSubscriptionChoice(rateEnum);
        }
    }, [rateEnumExists, rateEnum, setSubscriptionChoice]);

    return (
        <>
            <Box
                sx={{
                    display: {xs: 'none', sm: 'block'}
                }}
            >
                <CustomTextField
                    variant={"outlined"}
                    value={subscriptionChoice}
                    label={"Type d'abonnement"}
                    setField={(fieldValue) => setSubscriptionChoice(fieldValue)}
                    select
                    selectOptions={[
                        <MenuItem key={'STARTER'} value={'STARTER'}>Starter: Aucun frais mensuel, 5% par transaction
                            (min. 0.50CHF)</MenuItem>,
                        <MenuItem key={'PRO'} value={'PRO'}>Pro: Aucun frais mensuel, 5% par transaction (min.
                            0.50CHF)</MenuItem>,
                        <MenuItem key={'BOOKING'} value={'BOOKING'}>Réservation: Aucun frais mensuel, 5% par transaction
                            (min. 0.50CHF)</MenuItem>,
                        <MenuItem key={'SALES'} value={'SALES'}>Vente: Aucun frais mensuel, 5% par transaction (min.
                            0.50CHF)</MenuItem>,
                    ]}
                    mb={4}
                    textAlign={"left"}
                    required
                />
            </Box>
            <Box
                sx={{
                    display: {xs: 'block', sm: 'none'}
                }}
            >
                <CustomTextField
                    variant={"outlined"}
                    value={subscriptionChoice}
                    label={"Type d'abonnement"}
                    setField={(fieldValue) => setSubscriptionChoice(fieldValue)}
                    select
                    selectOptions={subscriptionsMenuItemOptions.map((subscriptionsMenuItemOption) => {
                            return (
                                <MenuItem
                                    key={subscriptionsMenuItemOption.rateEnum}
                                    value={subscriptionsMenuItemOption.rateEnum}
                                >
                                    {subscriptionsMenuItemOption.textShort}
                                </MenuItem>
                            )
                        }
                    )}
                    mb={4}
                    textAlign={"left"}
                    required
                />
            </Box>
        </>
        // <Box
        // sx={{
        //     width: 'fit-content',
        //     margin: 'auto'
        // }}
        // >
        //     <RadioGroup
        //         defaultValue="female"
        //         name="radio-buttons-group"
        //     >
        //         <FormControlLabel
        //             value="female"
        //             control={<Radio/>}
        //             label="Starter: Aucun frais mensuel, 5% par transaction (min 0.50CHF)"
        //         />
        //         <FormControlLabel
        //             value="male"
        //             control={<Radio/>}
        //             label="Pro: 20CHF/mois, 5% par transaction (min 0.50CHF)"
        //         />
        //         <FormControlLabel
        //             value="male"
        //             control={<Radio/>}
        //             label="Réservation: 30CHF/mois, 5% par transaction (min 0.50CHF)"
        //         />
        //         <FormControlLabel
        //             value="male"
        //             control={<Radio/>}
        //             label="Vente: 30CHF/mois, 5% par transaction (min 0.50CHF)"
        //         />
        //     </RadioGroup>
        // </Box>
    );
}

export default SubscriptionSelection