import React from "react";
import styles from "./Rates.module.css";
import Typography from "@mui/material/Typography";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import LineOfRates from "./LineOfRates/LineOfRates";
import Box from "@mui/material/Box";

const Rates = () => {

    const {ratesSection} = useSectionsRefs();

    return (
        <Box
            ref={ratesSection}
            sx={{
                backgroundColor: 'secondary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >

            <div className={styles.content}>
                <Typography
                    sx={{
                        fontSize: {xs: 30, sm: 40},
                    }}
                    fontWeight={400}
                    variant={"thirdVariant"}
                >
                    TARIFS
                </Typography>

                <Typography
                    sx={{
                        fontSize: {xs: 18, sm: 20, md: 24},
                        mb: 4,
                    }}
                    variant={"thirdVariant"}
                    textAlign={"justify"}
                    fontWeight={400}
                >
                    Différents plans vous sont proposés en fonction des besoins de votre société. Le passage de l’un à
                    l’autre peut se faire à tout moment.
                </Typography>

                <LineOfRates/>
            </div>
        </Box>

    );
}

export default Rates