import React from "react";
import CustomTextField from "../../customMUIComponents/CustomTextField";

interface SocietyDataProps {
    societyName: string,
    setSocietyName: (societyName: string) => void,
    activitySector: string,
    setActivitySector: (activitySector: string) => void,
    zip: string,
    setZip: (zip: string) => void,
    city: string,
    setCity: (city: string) => void,
    street: string,
    setStreet: (street: string) => void,
    streetNumber: string,
    setStreetNumber: (streetNumber: string) => void,
}

const SocietyData = ({
                         societyName,
                         setSocietyName,
                         activitySector,
                         setActivitySector,
                         zip,
                         setZip,
                         city,
                         setCity,
                         street,
                         setStreet,
                         streetNumber,
                         setStreetNumber,
                     }: SocietyDataProps) => {

    return (
        <>
            <CustomTextField
                value={societyName}
                setField={setSocietyName}
                label="Nom de la société"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={activitySector}
                setField={setActivitySector}
                label="Secteur d'activité"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={zip}
                setField={setZip}
                label="NPA"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={city}
                setField={setCity}
                label="Localité"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={street}
                setField={setStreet}
                label="Rue"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={streetNumber}
                setField={setStreetNumber}
                label="N° de rue"
                required
                mb={4}
                variant={"outlined"}
            />
        </>
    );
}

export default SocietyData