import {FormControl, InputAdornment, TextField} from "@mui/material";
import React, {ReactElement} from "react";

interface CustomTextFieldProps {
    value: string,
    label: string,
    type?: string,
    setField: (value: any) => void;
    required?: boolean,
    multiline?: boolean,
    rows?: number,
    variant?: "filled" | "outlined",
    mt?: number,
    mb?: number,
    ml?: number,
    mr?: number,
    select?: boolean,
    selectOptions?: ReactElement[],
    textAlign?: string,
    endAdornment?: string,
}

const CustomTextField = ({
                             value,
                             label,
                             type = "text",
                             setField,
                             required = false,
                             multiline = false,
                             rows = 1,
                             mt = 0,
                             mb = 0,
                             ml = 0,
                             mr = 0,
                             variant = "filled",
                             select,
                             selectOptions,
                             textAlign = 'center',
                             endAdornment = undefined,
                         }: CustomTextFieldProps) => {

    return (
        <FormControl
            fullWidth
        >
            <TextField
                value={value}
                sx={{
                    textAlign: textAlign,
                    backgroundColor: "#ffffff",
                    color: "#000000",
                    mt: mt,
                    mb: mb,
                    ml: ml,
                    mr: mr,
                    "& label.Mui-focused": {
                        color: "secondary.main"
                    },
                    // focused color for input with variant='standard'
                    "& .MuiInput-underline:after": {
                        borderBottomColor: "secondary.main"
                    },
                    // focused color for input with variant='filled'
                    "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "secondary.main"
                    },
                    // focused color for input with variant='outlined'
                    "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                            borderColor: "secondary.main"
                        },
                        "& .MuiOutlinedInput-notchedOutline": {
                            borderColor: "primary.main",
                            borderWidth: "1px",
                        },
                    },

                }}
                variant={variant}
                onChange={e => setField && setField(e.target.value)}
                label={label}
                required={required}
                type={type}
                multiline={multiline}
                rows={rows}
                select={select}
                slotProps={{
                    input: {
                        endAdornment: endAdornment && <InputAdornment position="start">{endAdornment}</InputAdornment>
                    }
                }}
            >
                {selectOptions && selectOptions.map((selectOption) => selectOption)}
            </TextField>
        </FormControl>

    );
}

export default CustomTextField