import React from "react";
import CustomTextField from "../../customMUIComponents/CustomTextField";

interface PersonnalDataProps {
    firstname: string,
    setFirstname: (firstname: string) => void,
    lastname: string,
    setLastname: (lastname: string) => void,
    phoneNumber: string,
    setPhoneNumber: (phoneNumber: string) => void,
    email: string,
    setEmail: (email: string) => void,
    emailConfirmation: string,
    setEmailConfirmation: (emailConfirmation: string) => void,
    password: string,
    setPassword: (password: string) => void,
    passwordConfirmation: string,
    setPasswordConfirmation: (passwordConfirmation: string) => void,
}

const PersonnalData = ({
                           firstname,
                           setFirstname,
                           lastname,
                           setLastname,
                           phoneNumber,
                           setPhoneNumber,
                           email,
                           setEmail,
                           emailConfirmation,
                           setEmailConfirmation,
                           password,
                           setPassword,
                           passwordConfirmation,
                           setPasswordConfirmation,
                       }: PersonnalDataProps) => {

    return (
        <>
            <CustomTextField
                value={firstname}
                setField={setFirstname}
                label="Prénom"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={lastname}
                setField={setLastname}
                label="Nom de famille"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={phoneNumber}
                setField={setPhoneNumber}
                label="N° téléphone"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={email}
                type={"email"}
                setField={setEmail}
                label="Email"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={emailConfirmation}
                type={"email"}
                setField={setEmailConfirmation}
                label="Confirmation email"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={password}
                type={"password"}
                setField={setPassword}
                label="Mot de passe"
                required
                mb={4}
                variant={"outlined"}
            />
            <CustomTextField
                value={passwordConfirmation}
                type={"password"}
                setField={setPasswordConfirmation}
                label="Confirmation mot de passe"
                required
                mb={4}
                variant={"outlined"}
            />
        </>
    );
}

export default PersonnalData