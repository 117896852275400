import React from "react";
import {Button} from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {useNavigate} from "react-router-dom";

const LaunchRegister = () => {

    const navigate = useNavigate();

    return (
        <Box sx={{
            backgroundColor: 'primary.main',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
        }}>
            <Box
                display={"flex"}
                flexDirection={"column"}
                sx={{
                    width: 1200,
                    minHeight: {xs: 340, sm: 440},
                    padding: 3,
                }}
                justifyContent={"center"}
                alignItems={"center"}
            >
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 24, sm: 35},
                    }}
                    variant={"firstVariant"}
                >
                    Devenir visible en ligne n'a jamais été aussi facile
                </Typography>

                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 18, sm: 20, md: 24},
                        mt: 4,
                    }}
                    variant={"firstVariant"}
                >
                    Vos futurs clients sont déjà sur Looty. Qu'attendez-vous pour les rejoindre ?

                </Typography>

                <Button
                    onClick={() => navigate('/register')}
                    variant="firstVariant"
                    fullWidth
                    sx={{
                        width: {xs: 200, sm: 400},
                        height: {xs: 40, sm: 50, md: 60},
                        fontSize: {xs: 16, sm: 20, md: 24},
                        mt: 4
                    }}
                >
                    Démarrer l'inscription
                </Button>
            </Box>
        </Box>
    );
}

export default LaunchRegister