import React from "react";
import {Button, Card, CardContent, Grid2, ListItemIcon} from "@mui/material";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Box from "@mui/material/Box";
import {Check, Clear} from "@mui/icons-material";
import {useNavigate} from "react-router-dom";
import {RateEnum} from "../../../../enums/RateEnum";

interface IncludedService {
    enabled: boolean,
    name: string,
    warning?: boolean,
}

interface RateDescription {
    title: string,
    rateEnum: RateEnum,
    societyServicesIncluded: IncludedService[],
    eventsServicesIncluded: IncludedService[],
    supportServicesIncluded: IncludedService[],
    monthlyPrice: string,
}

interface LineOfRatesProps {
    ratesDescriptions: RateDescription[]
}

const rates: RateDescription[] = [
    {
        title: 'Starter',
        rateEnum: RateEnum.STARTER,
        societyServicesIncluded: [
            {
                name: 'Vente en ligne',
                enabled: true,
            },
            {
                name: '3 articles maximum',
                enabled: true,
                warning: true,
            },
            {
                name: 'Point de retrait',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Programme',
                enabled: true,
            },
            {
                name: 'Vente de soutien',
                enabled: true,
            },
            {
                name: 'Bénévolat',
                enabled: false,
            },
            {
                name: 'Ticketing',
                enabled: false,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mail',
                enabled: true,
            },
            {
                name: 'Téléphone',
                enabled: false,
            },
            {
                name: 'Réponse en 7 jours',
                enabled: true,
                warning: true,
            },
        ],
        monthlyPrice: 'Gratuit',
    },
    {
        title: 'Pro',
        rateEnum: RateEnum.PRO,
        societyServicesIncluded: [
            {
                name: 'Vente en ligne',
                enabled: true,
            },
            {
                name: 'Articles illimités',
                enabled: true,
            },
            {
                name: 'Point de retrait',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Programme',
                enabled: true,
            },
            {
                name: 'Vente de soutien',
                enabled: true,
            },
            {
                name: 'Bénévolat',
                enabled: true,
            },
            {
                name: 'Ticketing',
                enabled: true,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mail',
                enabled: true,
            },
            {
                name: 'Téléphone',
                enabled: true,
            },
            {
                name: 'Réponse en 48h',
                enabled: true,
            },
        ],
        monthlyPrice: '20CHF',
    },
    {
        title: 'Réservation',
        rateEnum: RateEnum.BOOKING,
        societyServicesIncluded: [
            {
                name: 'Vente en ligne',
                enabled: true,
            },
            {
                name: '3 articles maximum',
                enabled: true,
            },
            {
                name: 'Point de retrait',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Programme',
                enabled: true,
            },
            {
                name: 'Vente de soutien',
                enabled: true,
            },
            {
                name: 'Bénévolat',
                enabled: true,
            },
            {
                name: 'Ticketing',
                enabled: true,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mail',
                enabled: true,
            },
            {
                name: 'Téléphone',
                enabled: true,
            },
            {
                name: 'Réponse en 48h',
                enabled: true,
            },
        ],
        monthlyPrice: '30CHF',
    },
    {
        title: 'Vente',
        rateEnum: RateEnum.SALES,
        societyServicesIncluded: [
            {
                name: 'Vente en ligne',
                enabled: true,
            },
            {
                name: '3 articles maximum',
                enabled: true,
            },
            {
                name: 'Point de retrait',
                enabled: true,
            },
        ],
        eventsServicesIncluded: [
            {
                name: 'Programme',
                enabled: true,
            },
            {
                name: 'Vente de soutien',
                enabled: true,
            },
            {
                name: 'Bénévolat',
                enabled: true,
            },
            {
                name: 'Ticketing',
                enabled: true,
            },
        ],
        supportServicesIncluded: [
            {
                name: 'Mail',
                enabled: true,
            },
            {
                name: 'Téléphone',
                enabled: true,
            },
            {
                name: 'Réponse en 48h',
                enabled: true,
            },
        ],
        monthlyPrice: '30CHF',
    },
]

const LineOfRates = () => {

    const navigate = useNavigate();

    return (
        <Grid2 container spacing={4}>
            {rates.map((rate: RateDescription) => (
                <Grid2 key={rate.title} size={{xs: 12, sm: 6, md: 6, lg: 3}}>
                    <Card sx={{borderRadius: 5}}>
                        <CardContent>
                            <Typography
                                variant="thirdVariant"
                                textAlign={"left"}
                                sx={{
                                    fontSize: {xs: 26, sm: 28, md: 30, lg: 35}
                                }}
                            >
                                {rate.title}
                            </Typography>
                            <Divider sx={{mt: 1, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    minHeight: {xs: 0, sm: 0}
                                }}
                                >
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        textAlign={"left"}
                                        sx={{mb: 2}}
                                    >
                                        Commerce
                                    </Typography>
                                    {rate.societyServicesIncluded.map((serviceIncluded, index) => (
                                        <ListItem key={index} sx={{mt: -2, pl: 0}}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 30,
                                                }}
                                            >
                                                {serviceIncluded.enabled ? (
                                                    <Check color={serviceIncluded.warning ? "warning" : "success"}/>
                                                ) : (
                                                    <Clear color={"error"}/>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={serviceIncluded.name}
                                                          primaryTypographyProps={{
                                                              fontSize: {
                                                                  xs: 16,
                                                                  sm: 16,
                                                                  md: 18
                                                              }
                                                          }}/>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Grid2>
                            <Divider sx={{mt: 0, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    minHeight: {xs: 0, sm: 0}
                                }}
                                >
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        textAlign={"left"}
                                        sx={{mb: 2}}
                                    >
                                        Événements
                                    </Typography>
                                    {rate.eventsServicesIncluded.map((serviceIncluded, index) => (
                                        <ListItem key={index} sx={{mt: -2, pl: 0}}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 30,
                                                }}
                                            >
                                                {serviceIncluded.enabled ? (
                                                    <Check color={serviceIncluded.warning ? "warning" : "success"}/>
                                                ) : (
                                                    <Clear color={"error"}/>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={serviceIncluded.name}
                                                          primaryTypographyProps={{
                                                              fontSize: {
                                                                  xs: 16,
                                                                  sm: 16,
                                                                  md: 18
                                                              }
                                                          }}/>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Grid2>
                            <Divider sx={{mt: 0, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    minHeight: {xs: 0, sm: 150}
                                }}
                                >
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={20}
                                        textAlign={"left"}
                                        sx={{mb: 2}}
                                    >
                                        Suport
                                    </Typography>
                                    {rate.supportServicesIncluded.map((serviceIncluded, index) => (
                                        <ListItem key={index} sx={{mt: -2, pl: 0}}>
                                            <ListItemIcon
                                                sx={{
                                                    minWidth: 30,
                                                }}
                                            >
                                                {serviceIncluded.enabled ? (
                                                    <Check color={serviceIncluded.warning ? "warning" : "success"}/>
                                                ) : (
                                                    <Clear color={"error"}/>
                                                )}
                                            </ListItemIcon>
                                            <ListItemText primary={serviceIncluded.name}
                                                          primaryTypographyProps={{
                                                              fontSize: {
                                                                  xs: 16,
                                                                  sm: 16,
                                                                  md: 18
                                                              }
                                                          }}/>
                                        </ListItem>
                                    ))}
                                </Box>
                            </Grid2>
                            <Divider sx={{mt: 0, mb: 1}}/>
                            <Grid2>
                                <Box sx={{
                                    display: 'flex',
                                    flexDirection: 'row'
                                }}>
                                    <Typography
                                        variant="thirdVariant"
                                        fontSize={25}
                                        textAlign={"left"}
                                    >
                                        {rate.monthlyPrice}
                                    </Typography>
                                    {rate.monthlyPrice !== 'Gratuit' && (
                                        <Typography
                                            variant="thirdVariant"
                                            fontSize={20}
                                            alignContent={'end'}
                                            sx={{
                                                ml: 0.5
                                            }}
                                        >
                                            /mois
                                        </Typography>
                                    )}
                                </Box>
                                <Button
                                    sx={{mt: 2}}
                                    variant={"firstVariant"}
                                    fullWidth
                                    // lowercase for aesthetic reason, uppercase is done in SubscriptionSelection component
                                    onClick={() => navigate(`/register#${rate.rateEnum.toLowerCase()}`)}
                                >
                                    Démarrer
                                </Button>
                            </Grid2>
                        </CardContent>
                    </Card>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default LineOfRates