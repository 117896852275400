import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./context/AuthProvider";
import {SocietyProvider} from "./context/SocietyProvider";
import {BasketProvider} from "./context/BasketProvider";
import {createTheme, ThemeProvider} from "@mui/material";
import ScrollToTop from "./components/scrollToTop/ScrollToTop";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

declare module '@mui/material/styles' {
    interface Components {
        [key: string]: any
    }

    interface Palette {
        tertiary: PaletteOptions['primary'];
        quaternary: PaletteOptions['primary'];
        quintenary: PaletteOptions['primary'];
    }

    interface PaletteOptions {
        tertiary: PaletteOptions['primary'];
        quaternary: PaletteOptions['primary'];
        quintenary: PaletteOptions['primary'];
    }

    interface PaletteColorOptions {
        main: string
    }
}

declare module "@mui/material/Button" {
    interface ButtonPropsVariantOverrides {
        firstVariant: true;
        secondVariant: true;
        thirdVariant: true;
        fourthVariant: true;
    }
}

declare module "@mui/material/Typography" {
    interface TypographyPropsVariantOverrides {
        firstVariant: true;
        secondVariant: true;
        thirdVariant: true;
    }
}

declare module "@mui/material/IconButton" {
    interface IconButtonOwnProps {
        variant?: "firstVariant" | "secondVariant";
    }
}

declare module "@mui/material/TextField" {
    interface TextfieldPropsVariantOverrides {
        basic: true;
    }
}

const theme1 = createTheme({
    palette: {
        primary: {
            main: '#543B26',
        },
        secondary: {
            main: '#B0754D',
        },
        tertiary: {
            main: '#D6B59C',
        },
        quaternary: {
            main: '#8C705E'
        },
        quintenary: {
            main: '#FFFFFF'
        },
    },
});

const theme2 = createTheme({
    palette: {
        primary: {
            main: '#011F4B',
        },
        secondary: {
            main: '#354476',
        },
        tertiary: {
            main: '#C0CAFF',
        },
        quaternary: {
            main: '#626EA4',
        },
        quintenary: {
            main: '#FFFFFF'
        },
    },
});

const theme3 = createTheme({
    palette: {
        primary: {
            main: '#234D20',
        },
        secondary: {
            main: '#457040',
        },
        tertiary: {
            main: '#B4E3AC',
        },
        quaternary: {
            main: '#699562',
        },
        quintenary: {
            main: '#FFFFFF'
        },
    },
});

const passedTheme = theme1;

const selectedTheme = createTheme({
    components: {
        MuiButton: {
            variants: [
                {
                    // use the variant name defined earlier
                    props: {variant: "firstVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: passedTheme.palette.secondary.main,
                        textTransform: "none"
                    },
                },
                {
                    // use the variant name defined earlier
                    props: {variant: "secondVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: passedTheme.palette.quaternary!.main,
                        textTransform: "none"
                    },
                },
                {
                    // use the variant name defined earlier
                    props: {variant: "thirdVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: passedTheme.palette.tertiary!.main,
                        textTransform: "none"
                    },
                },
                {
                    // use the variant name defined earlier
                    props: {variant: "fourthVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#ffffff",
                        backgroundColor: passedTheme.palette.primary!.main,
                        textTransform: "none"
                    },
                },
            ]
        },
        MuiTextField: {
            // styleOverrides: {
            //     root: {
            //         backgroundColor: "#ffffff",
            //         color: "#000000",
            //         "& label.Mui-focused": {
            //             color: passedTheme.palette.secondary.main
            //         },
            //         // focused color for input with variant='standard'
            //         "& .MuiInput-underline:after": {
            //             borderBottomColor: passedTheme.palette.secondary.main
            //         },
            //         // focused color for input with variant='filled'
            //         "& .MuiFilledInput-underline:after": {
            //             borderBottomColor: passedTheme.palette.secondary.main
            //         },
            //         // focused color for input with variant='outlined'
            //         "& .MuiOutlinedInput-root": {
            //             "&.Mui-focused fieldset": {
            //                 borderColor: passedTheme.palette.secondary.main
            //             },
            //             "& .MuiOutlinedInput-notchedOutline": {
            //                 borderColor: passedTheme.palette.primary.main,
            //                 borderWidth: "1px",
            //             },
            //         },
            //     }
            // }
        },
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: "#ffffff"
                }
            }
        },
        MuiTypography: {
            variants: [
                {
                    // use the variant name defined earlier
                    props: {variant: "firstVariant"},
                    // set the styles for this variant
                    style: {
                        color: "#000000",
                        display: 'block',
                        fontFamily: 'insignia',
                    },
                },
                {
                    // ue the variant name defined earlier
                    props: {variant: "secondVariant"},
                    // set the styles for this variant
                    style: {
                        color: passedTheme.palette.primary.main,
                        display: 'block',
                        fontFamily: 'insignia',
                    },
                },
                {
                    // ue the variant name defined earlier
                    props: {variant: "thirdVariant"},
                    // set the styles for this variant
                    style: {
                        color: passedTheme.palette.primary.main,
                        display: 'block',
                        fontFamily: 'insignia',
                    },
                },
            ],
        },
        MuiIconButton: {
            variants: [
                {
                    // use the variant name defined earlier
                    props: {variant: "firstVariant"},
                    // set the styles for this variant
                    style: {
                        color: passedTheme.palette.primary.main,
                        backgroundColor: "#ffffff"
                    },
                },
                {
                    // use the variant name defined earlier
                    props: {variant: "secondVariant"},
                    // set the styles for this variant
                    style: {
                        color: passedTheme.palette.quintenary!.main,
                    },
                },
            ]
        },
    },
}, passedTheme)

root.render(
    <BrowserRouter>
        <ScrollToTop/>
        <SocietyProvider>
            <BasketProvider>
                <AuthProvider>
                    <ThemeProvider theme={selectedTheme}>
                        <App/>
                    </ThemeProvider>
                </AuthProvider>
            </BasketProvider>
        </SocietyProvider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
