import React from "react";
import styles from "./Services.module.css";
import Typography from "@mui/material/Typography";
import LineOfServices from "./LineOfServices/LineOfServices";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";
import Box from "@mui/material/Box";

const societiyServices = [
    {
        title: 'Site vitrine',
        image: '/web-browser.png',
        text: 'Vous ne possédez pas de site Internet et ne souhaitez pas passer des heures à en créer un ? Looty vous permet de mettre en place votre page en quelques clics seulement !',
    },
    {
        title: 'Vente en ligne',
        image: '/online-shopping.png',
        text: 'Proposez vos articles en ligne en offrant la possibilité à vos clients de payer par Twint ou par carte de crédit. Gérez votre stock, le mode de livraison et restez informé en temps réel lorsqu’une commande est réalisée.'
    },
    {
        title: 'Point de retrait',
        image: '/store2.png',
        text: 'Vous ne souhaitez pas gérer l’expédition de vos articles ? Pas de problèmes, définissez un point de retrait ainsi que des horaires de passage et ce sont vos clients qui passeront retirer leur commande.'
    },
    {
        title: 'Rendez-vous',
        image: '/calendar.png',
        text: 'Que vous soyez coiffeur, masseur ou physio, vos clients pourront réserver leur rendez-vous sans avoir à vous téléphoner. Synchronisez votre agenda avec votre téléphone et modifiez à tout moment vos disponibilités.'
    },
]

const eventServices = [
    {
        title: 'Programme',
        image: '/schedule.png',
        text: 'Rendez le programme de votre manifestation accessible à tous en définissant simplement les lieux, les dates et les horaires des différentes activités.',
    },
    {
        title: 'Vente de soutien',
        image: '/salesSupport.png',
        text: 'Une vente de soutien est prévue pour votre manifestation ? Améliorez vos ventes en proposant les commandes sur votre page et en laissant le choix aux clients de payer directement ou par bulletin de versement.'
    },
    {
        title: 'Bénévolat',
        image: '/support.png',
        text: 'Définissez les tranches horaires de votre manifestation et recrutez facilement des bénévoles. Ces derniers peuvent s’inscrire individuellement ou en groupe. Envoyez-leur des rappels par mail et sms et exportez vos plannings.'
    },
    {
        title: 'Ticketing',
        image: '/tickets.png',
        text: 'Un souper spectacle ou une soirée VIP est  prévue lors de votre manifestation ? Vos billets peuvent facilement être vendus sur votre page et leur authenticité est assurée.'
    },
]


const Services = () => {

    const {servicesSection} = useSectionsRefs();

    return (
        <Box
            ref={servicesSection}
            sx={{
                backgroundColor: 'tertiary.main',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
            }}
        >


            <div className={styles.content}>
                <Typography
                    sx={{
                        fontSize: {xs: 30, sm: 40},
                    }}
                    fontWeight={400}
                    variant={"thirdVariant"}
                >
                    SERVICES
                </Typography>

                <Typography
                    sx={{
                        fontSize: {xs: 22, sm: 26, md: 30},
                        mt: 2,
                        mb: 4,
                    }}
                    fontWeight={400}
                    variant={"thirdVariant"}
                >
                    Pour la gestion de votre société
                </Typography>

                <LineOfServices servicesDescriptions={societiyServices}/>

                <Typography
                    sx={{
                        fontSize: {xs: 22, sm: 26, md: 30},
                        mt: 8,
                        mb: 4,
                    }}
                    fontWeight={400}
                    variant={"thirdVariant"}
                >
                    Pour la gestion de vos événements
                </Typography>

                <LineOfServices servicesDescriptions={eventServices}/>
            </div>
        </Box>
    );
}

export default Services