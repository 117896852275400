import React from "react";
import {Grid2} from "@mui/material";
import Typography from "@mui/material/Typography";
import services from "../Services";

interface serviceDescription {
    title: string,
    image: string,
    text: string,
}

interface LineOfServiceProps {
    servicesDescriptions: serviceDescription[]
}

const LineOfServices = ({servicesDescriptions}: LineOfServiceProps) => {

    return (
        <Grid2 container spacing={8}>
            {servicesDescriptions.map((service) => (
                <Grid2 key={service.title} size={{xs: 12, md: 6, lg: 3}}>
                    <Grid2
                        container
                        spacing={4}
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'row', sm: 'row', md: 'column'},
                            textAlign: 'left'
                        }}>
                        <Grid2
                            size={{
                                xs: 3,
                                sm: 2,
                                md: 12,
                            }}
                        >
                            <img src={service.image} alt={service.title}/>
                        </Grid2>
                        <Grid2
                            size={{
                                xs: 9,
                                sm: 10,
                                md: 12,
                            }}
                        >
                            <Typography
                                sx={{
                                    mt: 0,
                                    fontSize: {xs: 22, sm: 26, md: 30},
                                }}
                                variant={"secondVariant"}
                            >
                                {service.title}
                            </Typography>
                            <Typography
                                sx={{
                                    textAlign: 'left',
                                    fontSize: {xs: 16, sm: 20, md: 24},
                                }}
                                variant={"secondVariant"}
                                component="h3"
                                fontWeight={300}
                            >
                                {service.text}
                            </Typography>
                        </Grid2>
                    </Grid2>
                </Grid2>
            ))}
        </Grid2>
    );
}

export default LineOfServices