import React from "react";
import Typography from "@mui/material/Typography";
import {Grid2, Link} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import styles from "./Footer.module.css";

const Footer = () => {

    return (
        <footer className={styles.footer}>
            <div className={styles.content}>
                <Grid2 container sx={{minHeight: 50, alignContent: "center"}}>
                    <Grid2
                        size={{xs: 12, sm: 4}}
                        sx={{alignContent: "center"}}
                        textAlign={{xs: "center", sm: "left"}}>
                        <Typography
                            variant={"secondVariant"}
                            alignContent={"center"}
                        >
                            LOOTY © 2024
                        </Typography>
                    </Grid2>
                    <Grid2 size={{xs: 12, sm: 4}}>
                        <IconButton
                            aria-label="Instagram"
                            variant="firstVariant"
                        >
                            <InstagramIcon/>
                        </IconButton>
                        <IconButton
                            aria-label="LinkedIn"
                            variant="firstVariant"
                        >
                            <LinkedInIcon/>
                        </IconButton>
                    </Grid2>
                    <Grid2
                        size={{xs: 12, sm: 4}}
                        alignContent={"center"}
                        textAlign={{xs: "center", sm: "right"}}
                    >
                        <Link
                            variant={"firstVariant"}
                            href="#"
                        >
                            Conditions d'utilisation
                        </Link>
                    </Grid2>
                </Grid2>
            </div>
        </footer>
    );
}

export default Footer