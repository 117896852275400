import {AutocompleteRenderInputParams, FormControl, TextField} from "@mui/material";
import React from "react";

interface CustomTextFieldProps {
    setField?: (value: any) => void;
    params?: AutocompleteRenderInputParams;
}

const CustomGlobalSearchTextField = ({setField, params}: CustomTextFieldProps) => {

    const slotProps: any = params ? {
        input: {
            ...params.InputProps,
            type: 'text',
        },
    } : {}

    return (
        <FormControl
            fullWidth
            sx={{
                // ".MuiFormControl-root": {
                //     backgroundColor: "inherit",
                // }
            }}
        >
            <TextField
                sx={{
                    ".MuiFilledInput-root": {
                        borderRadius: "100px",
                        backgroundColor: "white",
                        borderBottom: "0px",
                    },
                    ".MuiFilledInput-root:hover": {
                        borderRadius: "100px",
                        backgroundColor: "white",
                        borderBottom: "0px",
                    },
                    ".MuiFilledInput-root:not(:hover)": {
                        borderRadius: "100px",
                        backgroundColor: "white",
                    },

                    ".MuiFilledInput-root::before": {
                        borderRadius: "100px",
                        display: 'none',
                    },

                }}
                variant={"filled"}
                onChange={e => setField && setField(e.target.value)}
                {...params}
                label="Recherchez un commerçant"
                slotProps={slotProps}
            />
        </FormControl>

    );
}

export default CustomGlobalSearchTextField