import React, {useState} from "react";
import styles from "./SocietyHomepageContact.module.css";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {Button, Stack} from "@mui/material";
import CustomTextField from "../../customMUIComponents/CustomTextField";
import {useSectionsRefs} from "../../mainWrapper/MainWrapper";

const SocietyHomepageContact = () => {

    const [firstname, setFirstname] = useState<string>('');
    const [lastname, setLastname] = useState<string>('');
    const [society, setSociety] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [phoneNumber, setPhoneNumber] = useState<string>('');
    const [message, setMessage] = useState<string>('');

    const {contactSection} = useSectionsRefs();

    const submit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        alert('Work in progress');
    }

    return (
        <Box
            ref={contactSection}
            sx={{
                backgroundColor: "tertiary.main",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 30, sm: 40},
                    }}
                    variant={"firstVariant"}
                >
                    CONTACT
                </Typography>


                <Box
                    mt={2}
                    mb={4}
                >
                    <img
                        className={styles.fakeMapToDelete}
                        src={'fake-map.png'}
                        alt="looty logo"
                    />
                </Box>

                <form onSubmit={(e) => submit(e)}>
                    <Stack spacing={2} direction="row" sx={{marginBottom: 4}}>
                        <CustomTextField
                            value={firstname}
                            label="Prénom"
                            setField={setFirstname}
                            required/>
                        <CustomTextField
                            value={lastname}
                            label="Nom"
                            setField={setLastname}
                            required/>
                    </Stack>

                    <CustomTextField
                        value={society}
                        label="Société" setField={setSociety}/>

                    <Stack spacing={2} direction="row" sx={{marginTop: 4}}>
                        <CustomTextField
                            value={email}
                            label="Email"
                            type="email"
                            setField={setEmail}
                            required/>
                        <CustomTextField
                            value={phoneNumber}
                            label="N° téléphone"
                            type="text"
                            setField={setPhoneNumber}
                            required/>
                    </Stack>

                    <CustomTextField
                        value={message}
                        label="Votre message"
                        setField={setMessage}
                        required
                        multiline
                        rows={6}
                        mt={4}
                        mb={4}/>

                    <Button
                        variant="fourthVariant"
                        type="submit"
                        fullWidth
                    >
                        Envoyer
                    </Button>
                </form>

            </div>
        </Box>
    );
}

export default SocietyHomepageContact