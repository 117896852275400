import {Grid2, Link} from "@mui/material";
import React from "react";
import CustomTextField from "../../customMUIComponents/CustomTextField";
import Typography from "@mui/material/Typography";

interface OtherInformationDataProps {
    subdomain: string,
    setSubdomain: (subdomain: string) => void,
    societyDescription: string,
    setSocietyDescription: (societyDescription: string) => void,
}

const OtherInformationData = ({
                                  subdomain,
                                  setSubdomain,
                                  societyDescription,
                                  setSocietyDescription
                              }: OtherInformationDataProps) => {

    return (
        <>
            <Grid2 container spacing={2}>
                <Grid2 size={{xs: 12, sm: 12, md: 6,}}>
                    <CustomTextField
                        value={subdomain}
                        setField={setSubdomain}
                        label="Votre nom de domaine"
                        required
                        variant={"outlined"}
                        endAdornment={".looty.ch"}
                    />
                </Grid2>
                <Grid2
                    size={{xs: 12, sm: 12, md: 6}}
                    sx={{textAlign: 'left'}}
                >
                    {subdomain && (
                        <>
                            <Typography
                                variant={"firstVariant"}
                                textAlign={"left"}
                            >
                                Votre page Looty sera accessible via le lien suivant:
                            </Typography>
                            <Link
                                href={'https://google.com'}
                            >
                                https://{subdomain}.looty.ch
                            </Link>
                        </>
                    )}
                </Grid2>
            </Grid2>
            <CustomTextField
                value={societyDescription}
                setField={setSocietyDescription}
                label="Description de votre société"
                required
                mt={4}
                mb={4}
                variant={"outlined"}
                multiline
                rows={6}
            />
        </>
    )
        ;
}

export default OtherInformationData