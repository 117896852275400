import React from "react";
import styles from "./About.module.css";
import {useNavigate} from "react-router-dom";
import {Button} from "@mui/material";
import {useSectionsRefs} from "../mainWrapper/MainWrapper";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

const About = () => {

    const {aboutSection} = useSectionsRefs();

    const navigate = useNavigate();

    return (
        <Box
            ref={aboutSection}
            sx={{
                backgroundColor: "tertiary.main",
                display: "flex",
                justifyContent: "center",
            }}
        >
            <div className={styles.content}>
                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 30, sm: 40},
                    }}
                    variant={"firstVariant"}
                >
                    À PROPOS
                </Typography>

                <Typography
                    sx={{
                        color: '#ffffff',
                        fontSize: {xs: 18, sm: 20, md: 24},
                        mb: 2,
                        textAlign: "justify",
                    }}
                    variant={"firstVariant"}
                >
                    Looty a été fondé en 2024 afin de répondre aux besoins des petites sociétés souhaitant, entre
                    autres,
                    vendre leurs produits en lignes et organiser des manifestations. Les trois fondateurs ont su mettre
                    en
                    commun leur expertise respective dans la gestion de sociétés, l’organisation d’événements et le
                    développement informatique pour vous proposer une plateforme aussi simple d’utilisation que possible
                    et
                    qui vous permette de faire connaître votre activité au plus grand nombre. Le concept vous intéresse
                    ?
                    Alors n’attendez plus et enregistrez votre société en 2 minutes.
                </Typography>

                <Button
                    variant="secondVariant"
                    onClick={() => navigate('/info')}
                    sx={{
                        mt: 2,
                        fontSize: {xs: 16, sm: 18, md: 20},
                    }}
                >
                    Inscrire ma société
                </Button>

            </div>
        </Box>
    );
}

export default About